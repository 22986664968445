import http from "../services/axios.service";
import { showApiError } from "../fns/message";
import {
  GetSectorApiResponse,
  GetUserApiResponse,
  GetUserClaimCountApiResponse,
  UpdatePhotoApiParam,
  UpdateSectorApiParam,
  UpdateUserApiParam,
} from "./apiTypes";
import { updateProfile } from "../reducers/profileReducer";
import { AxiosError, AxiosResponse } from "axios";
import Bugsnag from "@bugsnag/js";

export const getUser = (
  onSucceed: (data: GetUserApiResponse) => void,
  onError: (err: AxiosError) => void,
  access?: string
) => {
  let headers;
  if (access) {
    headers = { authorization: `Bearer ${access}` };
  }
  http
    .get("/users/self/", { headers })
    .then((res) => {
      console.log("getUser", res);
      if (res.data.user) {
        onSucceed(res.data.user);
        Bugsnag.setUser(res.data.user)
      } else {
        onSucceed(res?.data);
      }
    })
    .catch(onError);
};

export const updateUser = (
  user: UpdateUserApiParam,
  onSucceed: () => void,
  dispatch: any
) => {
  http
    .patch("/users/self/", user)
    .then((res: AxiosResponse<GetUserApiResponse>) => {
      console.log("updateUser", res);
      dispatch(updateProfile(res.data));
      onSucceed();
    })
    .catch(showApiError);
};

export const getUserSector = (
  onSucceed: (data: GetSectorApiResponse) => void
) => {
  http
    .get("/users/self/sector_preference/")
    .then((res) => {
      console.log("getSector", res);
      onSucceed(res?.data);
    })
    .catch((err) => {
      showApiError(err);
    });
};

export const getUserClaimCount = (
  onSucceed: (data: GetUserClaimCountApiResponse) => void
) => {
  http
    .get("/users/self/stat/shipping/current/")
    .then((res) => {
      console.log("getUserClaimCount", res);
      onSucceed(res?.data);
    })
    .catch((err) => {
      showApiError(err);
    });
};

export const updateUserSector = (
  sector: UpdateSectorApiParam,
  onSucceed: (data: GetSectorApiResponse) => void
) => {
  http
    .post("/users/self/sector_preference/", JSON.stringify(sector))
    .then((res) => {
      console.log("updateUserSector", res);
      onSucceed(res?.data);
    })
    .catch((err) => {
      showApiError(err);
    });
};

export const uploadHelmetImage = async (
  image: File,
  onUploadProgress: (event: any) => void,
  onSucceed: (data: UpdateUserApiParam) => void
) => {
  let formData = new FormData();
  formData.append("image", image, "image.png");
  http
    .post("/users/self/helmet/", formData, {
      onUploadProgress,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      onSucceed(res?.data);
    })
    .catch(showApiError);
};

export const updateUserBank = (
  {
    file_bank,
    file_ssn,
    ssn,
    bank_info_bank_code,
    bank_info_account_number,
  }: UpdatePhotoApiParam,
  onSucceed: () => void,
  dispatch: any
) => {
  let formData = new FormData();
  file_ssn && formData.append("photo_ssn", file_ssn, file_ssn.name);
  file_bank && formData.append("photo_bank", file_bank, file_bank.name);
  ssn && formData.append("ssn", ssn);
  bank_info_bank_code &&
    formData.append("bank_info_bank_code", bank_info_bank_code);
  bank_info_account_number &&
    formData.append("bank_info_account_number", bank_info_account_number);
  console.log("file_ssn", file_ssn, file_bank);
  http
    .patch("/users/self/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res: AxiosResponse<GetUserApiResponse>) => {
      console.log("updateUserBank", res);
      dispatch(updateProfile(res.data));
      onSucceed();
    })
    .catch(showApiError);
};
